
import currencyApplet from './Applets/CurrencyComponent.vue'
import { ref, onMounted } from 'vue'
import { BIconPlayFill } from 'bootstrap-icons-vue'

export default {
  components: {
    currencyApplet,
    BIconPlayFill
  },
  props: {
    title: String,
    sections: { required: true }
  },

  setup (props: any) {
    const toggle = ref(0)

    function toggleBar () {
      if (window.innerWidth < 768) {
        toggle.value = toggle.value === 0 ? -15 : 0
      }
    }

    onMounted(() => {
      toggleBar()
    })

    return {
      props,
      toggle,
      toggleBar
    }
  }
}
