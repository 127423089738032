
// import {  } from 'vue'

export default {
  props: {
    slides: Array,
    name: String
  },

  setup (props: any) {
    return {
      props
    }
  }
}

