
import { } from 'vue'
import json from '../../projects.json'
import Ficha from './Ficha.vue'
import { BIconCaretRightFill, BIconInfoCircle } from 'bootstrap-icons-vue'

export default {
  components: {
    Ficha,
    BIconCaretRightFill,
    BIconInfoCircle
  },

  setup () {
    const projectsData = json

    return {
      projectsData
    }
  }
}
