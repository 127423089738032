
import { ref, reactive } from 'vue'

export default {

  components: {
  },

  props: {
    tipo: String
  },
  emits: ['generateQr'],

  setup (props: Object, ctx: any) {
    const urlFormatted = ref('')
    const textFormatted = ref('')

    const vCardData = reactive({
      name: '',
      last: '',
      tlf: '',
      cell: '',
      email: '',
      web: '',
      org: '',
      title: '',
      street: '',
      city: '',
      country: ''
    })

    function generateUrlCode () {
      ctx.emit('generateQr', urlFormatted.value)
    }

    function generateTextCode () {
      ctx.emit('generateQr', textFormatted.value)
    }

    function generateVcardCode () {
      let vcardStr = 'BEGIN:VCARD\nVERSION:3.0'
      vcardStr += '\nN:' + vCardData.last + ';' + vCardData.name
      vcardStr += '\nFN:' + vCardData.name + ' ' + vCardData.last
      vcardStr += '\nORG:' + vCardData.org
      vcardStr += '\nTITLE:' + vCardData.title
      vcardStr += '\nADR:;;' + vCardData.street + ';' + vCardData.city + ';;;' + vCardData.country
      vcardStr += '\nTEL;WORK;VOICE:' + vCardData.tlf
      vcardStr += '\nTEL;CELL:' + vCardData.cell
      vcardStr += '\nTEL;FAX:'
      vcardStr += '\nEMAIL;WORK;INTERNET:' + vCardData.email
      vcardStr += '\nURL:' + vCardData.web
      vcardStr += '\nEND:VCARD'

      ctx.emit('generateQr', vcardStr)
    }

    return {
      props,
      urlFormatted,
      textFormatted,
      vCardData,
      generateUrlCode,
      generateTextCode,
      generateVcardCode
    }
  }
}
