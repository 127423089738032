
import { reactive, onMounted } from 'vue'
import { BIconCash } from 'bootstrap-icons-vue'
import axios from 'axios'

export default {

  components: {
    BIconCash
  },
  setup () {
    const currency = reactive({
      rates: [],
      base: '',
      selected: '0.0',
      start: 'CLP'
    })

    onMounted(() => {
      fetchCurrency()
    })

    function fetchCurrency () {
      const exchageStr:any = sessionStorage.getItem('exchange')

      if (exchageStr === null) {
        axios.get('https://pcmt93kogj.execute-api.eu-central-1.amazonaws.com/exchange')
          .then((response) => {
            currency.rates = response.data.rates
            currency.base = response.data.base
            currency.selected = response.data.rates[currency.start]

            sessionStorage.setItem('exchange', JSON.stringify(response.data))
          })
      } else { // Get data from session storage
        const exchange:any = JSON.parse(exchageStr)
        currency.rates = exchange.rates
        currency.base = exchange.base
        currency.selected = exchange.rates[currency.start]
      }
    }

    return {
      currency
    }
  }
}
