
import { } from 'vue'
import { BIconGithub, BIconLinkedin } from 'bootstrap-icons-vue'

export default {
  components: {
    BIconGithub,
    BIconLinkedin
  },

  setup () {
  }
}
