
// import {  } from 'vue'
import weatherApplet from './Applets/WeatherComponent.vue'

interface MenuItem {
  route: string,
  name: string,
  src: string,
  submenu: MenuItem[]
}

export default {
  components: {
    weatherApplet
  },
  setup () {
    const menus: MenuItem[] = [
      { route: 'Home', name: 'menus.navbar.home', src: '/', submenu: [] as MenuItem[] },
      { route: 'Projects', name: 'menus.navbar.projects', src: '/projects', submenu: [] as MenuItem[] },
      {
        route: 'Tools',
        name: 'menus.navbar.tools',
        src: '',
        submenu: [{ route: 'Qrcode', name: 'menus.navbar.toolsMenu.qrcode', src: '/qrcode', submenu: [] as MenuItem[] }]
      }
    ]

    return {
      menus
    }
  }
}
