
import { ref } from 'vue'
import Carousel from '../Carousel.vue'
import { BIconChevronUp, BIconChevronDown } from 'bootstrap-icons-vue'

export default {
  components: {
    Carousel,
    BIconChevronUp,
    BIconChevronDown
  },

  props: {
    item: Object
  },

  setup (props: any) {
    const showData = ref(true)

    const toggleData = () => {
      showData.value = !showData.value
    }

    return {
      data: props.item,
      showData,
      toggleData
    }
  }
}
