
import { onMounted } from 'vue'
import { BIconServer, BIconInfoCircle } from 'bootstrap-icons-vue'

export default {
  components: {
    BIconServer,
    BIconInfoCircle
  },

  setup () {
    onMounted(() => {
      // Load credly badges script on mounted
      const credlyScript = document.createElement('script')
      credlyScript.setAttribute('src', 'https://cdn.credly.com/assets/utilities/embed.js')
      document.head.appendChild(credlyScript)
    })
  }
}
