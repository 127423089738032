
import { ref } from 'vue'
import FillDataForm from './components/QR/DataForm.vue'
import Footer from './components/Footer/Footer.vue'
import QRCode from 'qrcode'

export default {
  components: {
    FillDataForm,
    Footer
  },

  setup () {
    const tipo = ref('url')
    const imageData = ref('')

    function getQr (text:String) {
      imageData.value = ''
      QRCode.toDataURL(text as string)
        .then(url => {
          imageData.value = url
        })
        .catch(err => {
          console.error(err)
        })
    }

    function downloadImg () {
      const link = document.createElement('a')
      link.href = imageData.value
      link.download = 'QrCode.png'
      link.click()
    }

    return {
      tipo,
      getQr,
      imageData,
      downloadImg
    }
  }
}
